import { useCallback, useEffect, useRef, useState } from 'react'
import { useConfig } from '../state/config'
import { useUser } from '../state/auth/currentUser'
import { useClient } from '../state/auth/currentClient'
import { useInstance } from '../state/auth/currentInstance'
import fonts from '../assets/fonts.json'
import { request } from '../state/api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined'
import { useTranslation } from '../services/i18n'
import { ReportDesigner } from './ReportDesigner'
import { DashboardDesigner } from './DashboardDesigner'
import { Viewer } from './Viewer'
import { Route, Routes, useParams, Link, useNavigate } from 'react-router-dom'
import { Report } from './Viewer'
import { Field, FormContent, Label } from '../components/Form'

export const ReportIndex = () => {
  const { section, id } = useParams()
  console.log(`SectionRender - Section: ${section}, ID: ${id}`) // Log the section and ID
  const navigate = useNavigate()

  const [loadedReports, setLoadedReports] = useState<[Report] | null>(null)
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false)
  const [rename, setRename] = useState({ id: '', name: '', type: '' })

  const loadReports = async () => {
    const { data } = await request('/reports')
    console.log('data: ', data)
    setLoadedReports(data)
  }

  const handleRemove = async (reportID: string) => {
    const { data } = await request(`/reports/${reportID}`, {
      method: 'delete',
      queryParameters: {},
    })

    console.log('deleted data: ', data)

    // If opened report is deleted than navigate after delete to update UI
    if (reportID === id) {
      navigate('/report')
    }
    loadReports()
  }

  const handleRename = async () => {
    const { data } = await request(`/reports/${rename.id}`, {
      method: 'put',
      jsonBody: { name: rename.name },
      queryParameters: {},
    })

    console.log('Renamed report: ', data)
    setRenameDialogIsOpen(false)

    updateSidebar()
  }

  const updateSidebar = useCallback(() => {
    loadReports()
  }, [])

  useEffect(() => {
    loadReports()
  }, [])

  const renderSection = () => {
    switch (section) {
      case 'edit-report':
        return <ReportDesigner id={id} updateSidebar={updateSidebar} />
      case 'edit-dashboard':
        return <DashboardDesigner id={id} updateSidebar={updateSidebar} />
      case 'view':
        return <Viewer id={id} />
      default:
        return <div>Select a section from the sidebar</div>
    }
  }

  return (
    <div style={{ display: 'flex', gap: 10, height: '100%' }}>
      {/* Sidebar */}
      <div style={{ background: 'gray', padding: 15, minWidth: 320, backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
          <Link to="/report/edit-report">
            <Button color="primary" variant="contained" size="small">
              <SummarizeOutlinedIcon fontSize="small" /> &nbsp; New Report
            </Button>
          </Link>
          <Link to="/report/edit-dashboard">
            <Button color="primary" variant="outlined" size="small">
              <DashboardOutlinedIcon fontSize="small" /> &nbsp; New Dashboard
            </Button>
          </Link>
        </div>

        {/* Loaded Reports */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 70 }}>
          {loadedReports
            ? loadedReports.map(report => (
                <div
                  style={{
                    background: '#F2F2F2',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 6,
                    paddingBottom: 3,
                    borderRadius: 3,
                    textDecoration: 'none',
                    color: 'black',
                  }}
                  key={report.id}>
                  <span
                    style={{
                      display: 'flex',
                      gap: 8,
                    }}>
                    {report.isDashboard ? <DashboardOutlinedIcon /> : <SummarizeOutlinedIcon />}
                    <Link
                      to={`/report/view/${report.id}`}
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                      }}>
                      {report.name}
                    </Link>
                  </span>

                  {section !== 'edit-report' && (
                    <span
                      style={{
                        display: 'flex',
                        gap: 10,
                        opacity: 0.7,
                      }}>
                      <Link
                        to={`/report/edit-report/${report.id}`}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                        }}>
                        Edit
                      </Link>
                      <div
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setRenameDialogIsOpen(true)
                          setRename({
                            id: report.id,
                            name: report.name,
                            type: report.isDashboard ? 'Dashboard' : 'Report',
                          })
                        }}>
                        <FormatShapesOutlinedIcon fontSize="small" />
                      </div>

                      <div
                        // to={`/report/delete-report/${report.id}`}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRemove(report.id)}>
                        <DeleteForeverIcon fontSize="small" />
                      </div>
                    </span>
                  )}
                </div>
              ))
            : ''}
        </div>

        {/* Dialog - Rename report */}
        <Dialog
          onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
          open={renameDialogIsOpen}
          disableEscapeKeyDown={true}
          onClose={() => {}}>
          <DialogTitle>Rename {rename.type}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '3em' }}>Change name</DialogContentText>
            <FormContent>
              <Label htmlFor="name">Rename</Label>
              <Field>
                <TextField
                  autoFocus
                  id="name"
                  type="text"
                  hiddenLabel
                  value={rename.name}
                  margin="dense"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={e => setRename(prev => ({ ...prev, name: e.target.value }))}
                />
              </Field>
            </FormContent>
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button variant="outlined" onClick={() => setRenameDialogIsOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleRename}>
                Rename
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      {!section ? (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}>
          Select &nbsp; <SummarizeOutlinedIcon /> &nbsp; Report or &nbsp;
          <DashboardOutlinedIcon /> &nbsp; Dashboard from the left tree
        </div>
      ) : (
        renderSection()
      )}
    </div>
  )
}

export const StimulsoftLicenseKey =
  '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnqzEyCXjn66MqXS5wxwumgToDoAQ9A7X0S3VlwTKBTxedhqeBHjG3UQRbccj3FwFjJ+Lnvo6PVg6SaVcIbSy9alThgJF2N+51tkUJHf4IML87wcEVdJ6TG/gIsC3fXwSBOjQ+NWiQd8TrAY7N5D2v/180BMOS45SBR1aJIUwfl9rttsZYNEahTDNZD8brBn3t+4VtNGXdBiKnMmoBixGFDTlZzz3HFZNEo1To6nYNN1/SR25R8SfPdE3YAl9SDM+IQg6NgXfuLkW/OsLZG1V4Zp3Cypbt2LPfEnHYfJ9C1XE3MDDUx//hts9b+O4P1iLJkGwWGXBjd2A74j/9VZYEmpXQxH0bWsPF3RN3b+cxgkkkf7927SAqlygMz6+Da8qs+mMhFjwuQDcrN9YxCpTRbE9H6FMBAznT4Y5wUK9Rni9rzAFQVYcg3CSe1AtlXorVUwPndpVZhnaI6VkVvbYT3u5OaC6Bb0Zb0kBzmbvhrIV/zc9+ZkvUsm+wEKNrNEt0Y67H5aJ3S6ZgJP/dS8+8s'
