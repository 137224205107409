import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'

import './base.css'
import './services/i18n'

import { Layout } from './components/layout/Layout'
import { Login } from './Login'
import { ThemeProvider } from './components/Theme'
import { useAuth } from './state/auth'
import { DeploymentTargets } from './DeploymentTargets'
import { InformationFlows } from './InformationFlows'
import { Dashboard } from './Dashboard'
import { Settings } from './Settings'
import { DataModel } from './DataModel'
import { SnackBarProvider } from './components/Snackbars'
import { Sources } from './Sources'
import { POCChecker } from './POCChecker'
import { Assignment } from './Assignments'
import { InstanceOptions } from './InstanceOptions'
import { ApiErrorNotification } from './components/ApiErrorNotification'
import { InstanceStatus } from './InstanceStatus'
import { Buffer } from 'buffer'
import { Overview } from './Overview'
import { ReportIndex } from './Report/Index'

declare global {
  interface Window {
    Buffer: any
  }
  // Extend the HTMLElement interface to include the custom property __root
  interface HTMLElement {
    __root?: ReactDOM.Root
  }
}

export const App = () => {
  window.Buffer = Buffer
  const isAuthenticated = useAuth(state => state.isLoggedIn)

  return (
    <StyledEngineProvider injectFirst>
      {/** @ts-ignore */}
      <ThemeProvider>
        <React.Suspense fallback={<div>Loading...</div>}>
          <SnackBarProvider>
            <Router>
              <ApiErrorNotification />
              <div className="App">
                {!isAuthenticated && <Login />}
                {isAuthenticated && <Body />}
              </div>
            </Router>
          </SnackBarProvider>
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const Body = () => {
  return (
    <Layout>
      <Routes>
        <Route path="wepocyet/*" element={<POCChecker />} />
        <Route path="status/*" element={<InstanceStatus />} />
        <Route path="sources/*" element={<Sources />} />
        <Route path="targets/*" element={<DeploymentTargets />} />
        <Route path="model/*" element={<DataModel />} />
        <Route path="assignments/*" element={<Assignment />} />
        <Route path="information-flows/*" element={<InformationFlows />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="instance-options/*" element={<InstanceOptions />} />
        <Route path="/report/:section/:id" element={<ReportIndex />} />
        <Route path="/report/:section" element={<ReportIndex />} />
        <Route path="report/*" element={<ReportIndex />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Layout>
  )
}

const container = document.getElementById('root') as HTMLElement
let root: ReactDOM.Root

if (!container.__root) {
  root = ReactDOM.createRoot(container)
  container.__root = root
} else {
  root = container.__root
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
