import { useEffect, useRef, useState } from 'react'
import {
  StiDesignerOptions,
  Stimulsoft,
  StiOptions,
} from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor'
import { useConfig } from '../state/config'
import { useUser } from '../state/auth/currentUser'
import { useClient } from '../state/auth/currentClient'
import { useInstance } from '../state/auth/currentInstance'
import fonts from '../assets/fonts.json'
import { request } from '../state/api'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import { useTranslation } from '../services/i18n'
import { Field, FormContent, Label } from '../components/Form'
import { StimulsoftLicenseKey } from './Index'

// export const loadFonts = async () => {
//   fonts.fonts.forEach(font => {
//     // Add custom font file
//     Stimulsoft.Base.StiFontCollection.addFontFileAsync(() => ({}), '/fonts/' + font)
//   })
// }

type Params = {
  id: string | undefined
  updateSidebar: () => void
}

export const ReportDesigner = (params: Params) => {
  const gsr_client = useClient(state => state.gsr_client)
  const gsr_inst = useInstance(state => state.gsr_inst)

  const argsRef = useRef<Stimulsoft.Designer.SaveReportArgs | null>(null)

  type Report = {
    id: string
    name: string
    group: string
    isDashboard: boolean
    template: object
    gsr_client: string | undefined
    gsr_inst: string
  }

  // Note id is set by the backend. We store it later for save and saveAs functionality
  const [report, setReport] = useState<Report>({
    id: params.id ? params.id : '',
    name: 'New Report ',
    group: '',
    isDashboard: false,
    template: {},
    gsr_client: gsr_client,
    gsr_inst: gsr_inst,
  })

  // const [reportData, setReportData] = useState<Report>(report)
  // const reportDataRef = useRef<Report | null>(null)

  const reportRef = useRef<Report | null>(null)
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)

  const [saveDialogIsOpen, setSaveDialogIsOpen] = useState(false)
  const [saveAsDialogIsOpen, setSaveAsDialogIsOpen] = useState(false)

  const { t } = useTranslation()

  const user = useUser()
  const client = useClient()
  const instance = useInstance()
  const { apiUrl } = useConfig()

  const variables = [
    {
      name: 'Client',
      value: client.gsr_client,
      description:
        "The UUID identifier of the client environment. All records available to the report have the provided value as the report cannot access other client's metadata.",
    },
    {
      name: 'ClientName',
      value: client.name,
      description:
        'The name of the client as defined in Flow.BI. This name identifies the client to the end-user.',
    },
    {
      name: 'Instance',
      value: instance.gsr_inst,
      description:
        'The UUID identifier of the instance. The report has access to all instances where the user account has access to due to the set permissions. A report therefore has to filter for the instance to provide a meaningful report for an instance.',
    },
    {
      name: 'InstanceName',
      value: instance.name,
      description:
        'The name of the instance as defined in Flow.BI. This name identifies the instance to the end-user.',
    },
    {
      name: 'AccountId',
      value: user.id,
      description:
        'The UUID to identify the user account of the currently logged in user.The UUID to identify the user account of the currently logged in user.',
    },
    {
      name: 'AccountPerspective',
      value: user.perspective,
      description: 'The perspective of the current user account: either business or technical.',
    },
    {
      name: 'AccountDbUser',
      value: user.database_user,
      description:
        'The database-level user name of the currently logged in user, which is part of the SQL key generated in Flow.BI to access the SQL API.',
    },
    {
      name: 'AccountName',
      value: user.name,
      description: 'The full name of the user the currently logged in user account belongs to.',
    },
    {
      name: 'AccountEmail',
      value: user.email,
      description: 'The Email address of the currently logged in user account.',
    },
  ]

  const languages = [
    { path: '/reports-locale/en.xml', language: 'English' },
    { path: '/reports-locale/de.xml', language: 'Deutch' },
    { path: '/reports-locale/fr.xml', language: 'French' },
    { path: '/reports-locale/ar.xml', language: 'Arabic' },
    { path: '/reports-locale/be.xml', language: 'Belarusian' },
    { path: '/reports-locale/cs.xml', language: 'Czech' },
    { path: '/reports-locale/es.xml', language: 'Spanish' },
    { path: '/reports-locale/fa.xml', language: 'Farsi' },
    { path: '/reports-locale/id.xml', language: 'Indonesian' },
  ]

  const loadReport = async () => {
    const { data } = await request(`/reports/${params.id}`, {
      method: 'get',
      queryParameters: {},
    })

    console.log('loadReport data: ', data)
    const updatedReport = data
    setReport(updatedReport)
    reportRef.current = updatedReport
    console.log('reportRef: ', reportRef.current)
  }

  useEffect(() => {
    const originalTitle = document.title
    // loadReport()
    // loadFonts()

    return () => {
      document.title = originalTitle
    }
  }, [])

  // Handle Window Resize
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const saveOperation = async () => {
    const args = argsRef.current
    if (args) {
      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, template: templateObject }
        sendUpdateRequest(updatedReport) // call api
        reportRef.current = updatedReport
        return updatedReport
      })

      const sendUpdateRequest = async (updatedReport: Report) => {
        if (updatedReport) {
          const { data } = await request(`/reports/${params.id}`, {
            method: 'put',
            jsonBody: updatedReport,
            queryParameters: {},
          })

          console.log('data: ', data)
          params.updateSidebar()
        }
      }

      // change title to current report name
      document.title = report.name + ' - Flow.BI'
    }
  }

  // const saveOperation = async () => {
  //   const args = argsRef.current
  //   console.log('args: ', args)
  //   if (args) {
  //     const templateString: string = args.report.saveToJsonString()
  //     const templateObject: object = JSON.parse(templateString)

  //     setReport(prevReport => {
  //       const updatedReport = { ...prevReport, template: templateObject }
  //       reportRef.current = updatedReport
  //       return updatedReport
  //     })

  //     const updatedReport = reportRef.current
  //     console.log('reportRef.current: ', reportRef.current)

  //     console.log('saving file ...')
  //     console.log('going to save following data: ', updatedReport)

  //     if (updatedReport) {
  //       // send an update request
  //       const { data } = await request(`/reports/${params.id}`, {
  //         method: 'put',
  //         jsonBody: updatedReport,
  //         queryParameters: {},
  //       })

  //       console.log('data: ', data)

  //       params.updateSidebar()
  //     }
  //   }
  // }

  // Save Report when SAVE button is pressed
  const handleSaveReport = async () => {
    const args = argsRef.current
    if (args) {
      const trimmedName = report.name.trim()
      args.report.reportName = trimmedName
      args.report.reportAlias = trimmedName

      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, name: trimmedName, template: templateObject }
        reportRef.current = updatedReport
        return updatedReport
      })

      const currentReport = reportRef.current
      console.log('currentReport', currentReport)

      if (currentReport) {
        const { data } = await request('/reports', {
          method: 'post',
          jsonBody: currentReport,
          queryParameters: {},
        })

        console.log('data: ', data)
        params.updateSidebar()

        setReport(prevReport => {
          const updatedReport = { ...prevReport, id: data.id }
          reportRef.current = updatedReport
          return updatedReport
        })

        setSaveDialogIsOpen(false)
      }

      // change title to current report name
      document.title = report.name + ' - Flow.BI'
    }
  }

  // handle saveAs report when SAVE button in pressed
  const handleSaveAsReport = async () => {
    const args = argsRef.current
    if (args) {
      const trimmedName = report.name.trim()
      args.report.reportName = trimmedName
      args.report.reportAlias = trimmedName

      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, name: trimmedName, template: templateObject }
        reportRef.current = updatedReport
        return updatedReport
      })

      const currentReport = reportRef.current
      console.log('currentReport', currentReport)

      if (currentReport) {
        const { data } = await request('/reports', {
          method: 'post',
          jsonBody: currentReport,
          queryParameters: {},
        })

        console.log('data: ', data)
        params.updateSidebar()

        setReport(prevReport => {
          const updatedReport = { ...prevReport, id: data.id }
          reportRef.current = updatedReport
          return updatedReport
        })

        setSaveAsDialogIsOpen(false)
        console.log('currentReport', reportRef.current)
      }

      // change title to current report name
      document.title = report.name + ' - Flow.BI'
    }
  }

  console.log('report: ', report)

  // // Load languages for Stimulsoft
  // useEffect(() => {
  //   try {
  //     for (const lang of languages) {
  //       Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
  //         lang.path,
  //         true,
  //         lang.language
  //       )
  //     }

  //     // Set preferred language of the user
  //     Stimulsoft.Base.Localization.StiLocalization.cultureName = user.preferred_language || 'en'
  //   } catch (error) {
  //     console.error('Error loading localization file: ', error)
  //   }
  // }, [])

  useEffect(() => {
    // Save page title. Change it on component unmount
    const originalTitle = document.title

    const run = async () => {
      // Stimulsoft activation
      Stimulsoft.Base.StiLicense.key = StimulsoftLicenseKey

      // Initialize Stimulsoft options and designer
      const options = new StiDesignerOptions()

      options.height = `${height - 100}px` // Compensate footer by reducing 100px
      options.width = `${width}px`
      options.appearance.allowChangeWindowTitle = false
      options.appearance.showSaveDialog = false
      options.toolbar.showFileMenuSaveAs = true
      options.toolbar.showFileMenuOpen = false
      options.toolbar.showFileMenuClose = false
      // Prevent user from adding new data source
      // options.dictionary.dataSourcesPermissions = Stimulsoft.Designer.StiDesignerPermissions.View
      // options.dictionary.dataRelationsPermissions = Stimulsoft.Designer.StiDesignerPermissions.View
      // options.dictionary.showAdaptersInNewConnectionForm = false
      // options.dictionary.dataConnectionsPermissions = Stimulsoft.Designer.StiDesignerPermissions.View

      const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false)

      // When save icon is pressed
      designer.onSaveReport = args => {
        argsRef.current = args
        // if (reportRef.current && reportRef.current.id) {
        if (params.id) {
          saveOperation()
        } else {
          setSaveDialogIsOpen(true)
        }
      }

      // When save as is pressed
      designer.onSaveAsReport = args => {
        setSaveAsDialogIsOpen(true)
        argsRef.current = args
      }

      // Configure Stimulsoft WebServer options
      StiOptions.WebServer.encryptData = false
      StiOptions.WebServer.checkDataAdaptersVersion = false
      const stimulsoftDataAdapterPort = 9615
      if (apiUrl) {
        StiOptions.WebServer.url = `${apiUrl}:${stimulsoftDataAdapterPort}`
      }

      try {
        const reportDesigner = Stimulsoft.Report.StiReport.createNewReport()
        if (!params.id) {
          // Load Blank file
          reportDesigner.loadFile('/reports/Report Designer.mrt')
        } else {
          const { data } = await request(`/reports/${params.id}`, {
            method: 'get',
            queryParameters: {},
          })

          console.log('loadReport data: ', data)

          document.title = data.name + ' - Flow.BI'

          setReport(data)
          reportRef.current = report
          const template = data.template
          console.log('template: ', template)

          reportDesigner.load(template)
          // change title to current report name
        }

        // Set client variables
        variables.forEach(({ name, description, value }) => {
          const variable = new Stimulsoft.Report.Dictionary.StiVariable()
          variable.name = name
          variable.description = description
          variable.value = value ? value : ''
          variable.readOnly = true
          variable.allowUseAsSqlParameter = true
          reportDesigner.dictionary.variables.add(variable)
        })

        // Pre-register a Postgres connection
        const dataSource = new Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase(
          'repo',
          'repo',
          'connectionString will be auto inserted by the server side.'
        )
        reportDesigner.dictionary.databases.add(dataSource)

        // Prevent user from editing existing database
        // report.dictionary.restrictions.add(
        //   'repo',
        //   Stimulsoft.Report.Dictionary.StiDataType.Database,
        //   Stimulsoft.Report.Dictionary.StiRestrictionTypes.DenyEdit
        // )

        // if (!params.id) {
        //   // Create a sample text
        //   var sampleText = new Stimulsoft.Report.Components.StiText()
        //   sampleText.clientRectangle = new Stimulsoft.System.Drawing.Rectangle(1, 1, 5, 2)
        //   sampleText.text = 'Sample Text in Space Grotesk'
        //   sampleText.font = new Stimulsoft.System.Drawing.Font('Space Grotesk Medium')
        //   sampleText.font.size = 21
        //   sampleText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All
        //   const page = reportDesigner.pages.getByIndex(0)
        //   page.components.add(sampleText)
        // }

        designer.report = reportDesigner

        // Modify page title
        // const reportName = reportDesigner.reportFile
        // const cleanupReportName = reportName.split('/').pop()?.split('.mrt')[0]
        // document.title = cleanupReportName + ' - Flow.BI'
      } catch (error) {
        console.error('Error loading report: ', error)
      }

      designer.renderHtml('renderDesigner')

      setLoading(false)
    }
    run()
  }, [height, params.id])

  return (
    <>
      {/* Dialog - save report */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={saveDialogIsOpen}
        disableEscapeKeyDown={true}
        onClose={() => {}}>
        <DialogTitle>Save Report</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>
            Enter details to save the file
          </DialogContentText>
          <FormContent>
            <Label htmlFor="name">{t('name')}</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={report.name}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, name: e.target.value })}
              />
            </Field>
            <Label htmlFor="group">{t('Folder')}</Label>
            <Field>
              <TextField
                autoFocus
                id="group"
                type="text"
                hiddenLabel
                value={report.group}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, group: e.target.value })}
              />
            </Field>
          </FormContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={() => setSaveDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveReport}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Dialog - saveAs report */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={saveAsDialogIsOpen}
        disableEscapeKeyDown={true}
        onClose={() => {}}>
        <DialogTitle>Save As Report</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>
            Enter details to save the file
          </DialogContentText>
          <FormContent>
            <Label htmlFor="name">{t('name')}</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={report.name}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, name: e.target.value })}
              />
            </Field>
            <Label htmlFor="group">{t('Folder')}</Label>
            <Field>
              <TextField
                autoFocus
                id="group"
                type="text"
                hiddenLabel
                value={report.group}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, group: e.target.value })}
              />
            </Field>
          </FormContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={() => setSaveAsDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveAsReport}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {loading && (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '100%',
          }}>
          <SummarizeOutlinedIcon /> &nbsp; Report is Loading ...
        </div>
      )}
      {/* Load Report Designer */}
      <div id="renderDesigner" style={{ width: loading ? '0px' : '100%' }}></div>
    </>
  )
}
