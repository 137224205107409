import React, { useEffect, useState } from 'react'
import {
  Stimulsoft,
  StiViewer,
  StiViewerOptions,
} from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer'
import { request } from '../state/api'
import { StimulsoftLicenseKey } from './Index'
// import { loadFonts } from './ReportDesigner'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'

type ViewerProp = {
  id: string | undefined
}

export type Report = {
  id: string
  name: string
  group: string
  isDashboard: boolean
  template: object
  gsr_client: string
  gsr_inst: string
}

export const Viewer = ({ id }: ViewerProp) => {
  const [reportData, setReportData] = useState<Report | null>(null)
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)

  const loadReport = async () => {
    const { data } = await request(`/reports/${id}`, {
      method: 'get',
      queryParameters: {},
    })

    console.log('data: ', data)

    setReportData(data)
    document.title = data.name + ' - Flow.BI'
  }

  // Handle Window Resize
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const originalTitle = document.title

    if (id) {
      loadReport()
      //   loadFonts()
    }

    return () => {
      document.title = originalTitle
    }
  }, [id])

  useEffect(() => {
    // Stimulsoft activation
    Stimulsoft.Base.StiLicense.key = StimulsoftLicenseKey

    if (reportData) {
      const template = reportData.template
      console.log('template: ', template)

      if (!Object.keys(template).length) console.error("Template can't be empty")

      const report = new Stimulsoft.Report.StiReport()

      report.onBeginRender = () => {
        console.log('onBeginRender')
        setLoading(true)
      }
      report.onEndRender = () => {
        console.log('onEndRender')
        setLoading(false)
      }
      report.load(template)

      const options = new StiViewerOptions()
      // Add option here
      options.appearance.scrollbarsMode = true
      options.height = `${height}px`
      //   options.width = `${width}px`
      options.appearance.backgroundColor = Stimulsoft.System.Drawing.Color.fromName('gray') // Use Stimulsoft color

      const viewer = new StiViewer(options, 'StiViewer', false)
      viewer.report = report

      viewer.renderHtml('renderViewer')
    }
  }, [reportData, height, width])

  if (!id) {
    return (
      <div
        style={{
          display: 'flex',
          color: 'white',
          width: '100%',
          backgroundColor: '#555',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}>
        Provide a valid &nbsp; <SummarizeOutlinedIcon /> &nbsp; Report or &nbsp;
        <DashboardOutlinedIcon /> &nbsp; Dashboard ID
      </div>
    )
  }
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '100%',
          }}>
          <SummarizeOutlinedIcon /> &nbsp; Report is Loading ...
        </div>
      )}
      <div id="renderViewer" style={{ width: loading ? '0px' : '100%' }}></div>
    </>
  )
}
