import { PropsWithChildren, useEffect, useState, useRef, ComponentProps } from 'react'
import { setLicenseKey, Tooltip } from '@grapecity/wijmo'
import { FlexGrid } from '@grapecity/wijmo.react.grid'
import '@grapecity/wijmo.styles/wijmo.css'
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input'
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { ApiRequestOptions } from '../state/api'
import { ApiConnectorCollectionView, GridEventHandler } from './DataGrid/ApiConnectorCollectionView'

// To get rid of Wijmo banner
setLicenseKey(
  'app.test.flbi.net|app.uat.flbi.net|app.prod.flbi.net|app.flow.bi|app.dev.flbi.net,364459815399839#B0x2SXbpjInxmZiwiIyYHNyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZ5Y6QXlmWxsEezJ6TVRHcZNzawMDctBzLwo5V8w4dQxGbTB7SMdjR62CWr3iVEx6NalDUoJDVWlWMtt4YSxUdFp5ciJUYxkXe7UHe5FXSD3Sd8hmatlGWmlnbPhDeoJGeqlUQ4c5Zqhja5skRkp5auFmMrtURKdkUUZHaBNEWM5URNpnVWNjN6IXMXB5L5p6LllFVkJURB5kd59UVjxkd7YUMtJWTSdGRwZjMJZ5b8xGSLNEOr9Wd6kVZQJmc5MnWSNzVvYGc5lnSR3EaERkaBhmMjdVaZ3yKyEDOUZWY6RmQiVDO9EEc6EEcDZTZxtkMwN4YMhXTBJnQ6wGR5YVOW5WMZpXRsJFOGFESkNXQplUR6kTMwR7TBdFWVpHR44kdW5mdYNDbaJXT4IGO5FTVvlUbSdGSkFTYKxEeLlzSltSUJdzTOdDeoBnYkVGb9knZNF7YtpmI0IyUiwiI6IUREF4QzYjI0ICSiwSOxMzNxQDO6QTM0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzMzN4YDMgAzM9ADNyAjMiojI4J7QiwiI4VmbukmYsZmL6VGZuAHchxSai9ydvxmZuAHchxCdl9mLpJGbm9CZvJHcuAHchxCdl9mLpJGbm9CdhVnLwBXYsQXZu9SaixmZuQ7clRnLwBXYiojIz5GRiwiIIJWbHBSSC9ydvxmRiojIh94QiwiI9MDO9kzM5EDO9UDN4YzMiojIKlKI'
)

interface DataGridProps {
  endpoint: string
  FlexGridProps?: ComponentProps<typeof FlexGrid>
  requestOptions?: ApiRequestOptions
  processRowUpdate?: GridEventHandler
  processRowCreation?: GridEventHandler
  processRowRemoval?: GridEventHandler
}

const defaultFlexGridProps: ComponentProps<typeof FlexGrid> = {
  selectionMode: 'MultiRange',
  autoGenerateColumns: false,
  alternatingRowStep: 0,
  treeIndent: 30,
  showSelectedHeaders: 'All',
  showMarquee: true,
  deferResizing: true,
  keyActionTab: 'Cycle',
}

export const DataGrid = (props: PropsWithChildren<DataGridProps>) => {
  const gridInstance = useRef<any>()
  const tooltip = useRef<any>()
  const collectionView = useRef<any>([])
  const flexGridProps = { ...defaultFlexGridProps, ...props.FlexGridProps }
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!tooltip.current) {
      tooltip.current = new Tooltip()
      tooltip.current.showDelay = 500
    }
  }, [])

  // Remove the pesky demo banner so we can enjoy the evaluation period for a while.
  // This is super compliant with their license of course, no legal trouble forseeable at all.
  // useEffect(() => { const interval = setInterval(() => {document.evaluate('//a[contains(text(),\'Wijmo Evaluation Version\')]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue?.parentElement?.remove()}, 1000); return () => clearInterval(interval)}, [])

  useEffect(() => {
    collectionView.current = new ApiConnectorCollectionView(
      props.endpoint,
      props.requestOptions,
      props.processRowUpdate,
      props.processRowCreation,
      props.processRowRemoval
    )

    collectionView.current.loading.addHandler(() => setIsLoading(true))
    collectionView.current.loaded.addHandler(() => setIsLoading(false))
  }, [
    props.processRowCreation,
    props.processRowUpdate,
    props.processRowRemoval,
    props.requestOptions,
    props.endpoint,
  ])

  const initGrid = (grid: any) => {
    gridInstance.current = grid

    if (!gridInstance.current) return
    flexGridProps.initGrid && flexGridProps.initGrid(grid)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        height: '100%',
        width: '100%',
        fontSize: '.85em',
        '.wj-flexgrid': {
          height: '100%',
        },
        '.wj-flexgrid .wj-cell': {
          transition: 'background .3s',
        },
        '.wj-flexgrid .wj-marquee, .wj-flexgrid .wj-header': {
          transition: 'all .3s',
        },
      }}>
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          overflow: 'hidden',
          mb: 1,
        }}>
        <Backdrop
          sx={{ position: 'absolute', color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <FlexGrid
          {...flexGridProps}
          initialized={initGrid}
          itemsSource={collectionView.current}
          formatItem={(s: any, e: any) => {
            if (e.panel === s.cells) {
              var item = s.rows[e.row].dataItem
              item && tooltip.current.setTooltip(e.cell, '')
            }
          }}>
          <FlexGridFilter />
          {props.children}
        </FlexGrid>
      </Box>
      <Box sx={{ margin: '0 auto' }}>
        <CollectionViewNavigator
          byPage={true}
          headerFormat="Page {currentPage:n0} of {pageCount:n0}"
          cv={collectionView.current}
        />
      </Box>
    </Box>
  )
}
