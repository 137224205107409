import { create } from 'zustand'
import { clientStore } from './currentClient'
import { request } from '../api'
import { Instance } from '../../../../api/app/instances'

const cacheId = (id: string) => window.localStorage.setItem('current-instance-id', id)
const flushId = () => window.localStorage.removeItem('current-instance-id')

export const requestLoad = async () => await toggleInstanceLoad(true)
export const cancelLoad = async () => await toggleInstanceLoad(false)

export const getInstanceJobs = async () => {
  const { data } = await request(`/instances/${instanceStore.getState().gsr_inst}/jobs`)

  return data
}

export const getSourceJobs = async () => {
  const { data } = await request(`/instances/${instanceStore.getState().gsr_inst}/source-jobs`)

  return data
}

const initialState = {
  gsr_inst: '',
  name: '',
  time_zone: '',
  can_request_load: false,
  load_requested: false,
  has_active_ldts_jobs: false,
  has_active_sdts_jobs: false,
  assignedRoles: [],
}

// export const fetchInstanceDashboardToken = async () => {
//   const gsr_inst = instanceStore.getState().gsr_inst

//   if (!gsr_inst) return null

//   const {
//     data: { guest_token },
//   } = await request(`/instances/${gsr_inst}/dashboard_token`)

//   return guest_token
// }
export const switchInstance = async (instance: Instance) => {
  const { data: fullInstance } = await request('/instances/' + instance.gsr_inst)
  instanceStore.setState(fullInstance, true)
}
export const instanceStore = create<Instance>(() => initialState)
export const useInstance = instanceStore

// Make sure we cache our instance id so that we are able to keep the selection across page loads. Note how we use a
// listener on the store for this. We could also do this in the setInstance() function on the state defined above, but
// that won't catch direct "setState" calls on the store.
instanceStore.subscribe(state => {
  if (state.gsr_inst) {
    cacheId(state.gsr_inst)
  } else {
    flushId()
  }
})

let unsubscribeClient: any

export const init = async (): Promise<boolean> => {
  unsubscribeClient && unsubscribeClient()
  // Make sure we update our store when the customer changes or is removed, because our store is a direct dependant of it.
  // The current instance naturally is directly linked to the current customer and is invalid as soon as the customer
  // changes. This might be due to logging out or because the user switched to another, nested client. We want to flush
  // our cached instance id and init from a blank page so that we can immediately reflect the actual state of the new
  // customer.
  unsubscribeClient = clientStore.subscribe(
    state => state.gsr_client,
    async (id?: string, oldId?: string) => {
      if (!id || id !== oldId) {
        //flushId()
        init()
      }
    }
    // state => state.gsr_client
  )

  // unsubscribeClient = clientStore.subscribe(
  //   async (id?: string, oldId?: string) => {
  //     if (!id || id !== oldId) {
  //       //flushId()
  //       init()
  //     }
  //   },
  //   state => state.gsr_client
  // )

  let instanceId = window.localStorage.getItem('current-instance-id')
  const clientId = clientStore.getState().gsr_client
  let instanceFromCache = true

  if (!instanceId) {
    instanceFromCache = false
    const { data: instances } = await request('/instances', {
      queryParameters: {
        gsr_client: clientId,
        page: 1,
        pageSize: 1,
        sortby: 'created_at',
        order: 'ASC',
      },
    })

    if (instances.length) {
      instanceId = instances[0].gsr_inst
    }
  }

  if (!instanceId) {
    // Make sure we start on a blank canvas again, we weren't able to load any instances for the current customer.
    instanceStore.setState(initialState, true)
    return false
  }

  try {
    const { data: instance } = await request('/instances/' + instanceId)
    instanceStore.setState(instance, true)
  } catch (e) {
    console.error(e)
    if (instanceFromCache) {
      // Seems like we cannot access the cached instance any longer, maybe the user switched between clients
      // within the same browser. Wipe the cache and start fresh.
      flushId()
      return await init()
    }

    // Well this aint good. Looks like we happened to fetch some instance from the client but when we tried
    // to read it we failed badly. Back to square one, fall back to default state.
    instanceStore.setState(initialState, true)
    return false
  }

  return true
}

const toggleInstanceLoad = async (state: boolean) => {
  instanceStore.setState({ load_requested: state })

  await request('/instances/' + instanceStore.getState().gsr_inst, {
    method: 'put',
    jsonBody: {
      load_requested: state,
    },
  })
}
