import React from 'react'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'

type DashboardDesignerParams = {
  id: string | undefined
  updateSidebar: () => void
}

export const DashboardDesigner = ({ id }: DashboardDesignerParams) => {
  return (
    <div
      style={{
        display: 'flex',
        color: 'white',
        width: '100%',
        backgroundColor: '#555',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      }}>
      <DashboardOutlinedIcon /> &nbsp; Coming soon. Please check back later.
    </div>
  )
}
